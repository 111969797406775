<template>
  <div class="agency-card-editor">
    <div class="agency-card-editor__wrapper">
      <Column>
        <Row class="agency-card-editor__avatar-holder"
             justify="between">
          <UserAvatar :params="{avatar: params.logo, size: 50}"/>
          <Icon v-if="params.status !== 'moderated'"
                class="agency-card-editor__settings-caller"
                v-on:click.native="$openPopupAddAgencies(params)"
                xlink="settings"
                viewport="0 0 20 20"/>
        </Row>
        <Column class="agency-card-editor__name-annotation">
          <span class="agency-card-editor__name">{{params.title}}</span>
          <span class="agency-card-editor__annotation">{{description}}</span>
        </Column>
        <Row justify="between"
             full>
          <Column v-if="params.phone"
                  class="agency-card-editor__title-value">
            <span class="agency-card-editor__title">Телефон</span>
            <span class="agency-card-editor__value">{{params.phone}}</span>
          </Column>
          <Column class="agency-card-editor__title-value">
            <span class="agency-card-editor__title">Город</span>
            <span class="agency-card-editor__value">{{city}}</span>
          </Column>
        </Row>
        <Row v-if="params && params.status === 'nonactive'"
             class="agency-card-editor__button-holder"
             full>
          <Button color="red"
                  v-on:click.native="$openPopupAddAgencies(params)"
                  full>Заявка отклонена
          </Button>
        </Row>
        <Row v-if="params && params.status === 'active'"
             class="agency-card-editor__button-holder"
             full>
          <Button color="like-link"
                  v-on:click.native="$openPopupAddAgencies(params)"
                  full>Профиль турфирмы
          </Button>
        </Row>
        <Row v-if="params && params.status === 'new'"
             class="agency-card-editor__button-holder"
             full>
          <Button color="green"
                  v-on:click.native="$openPopupAddAgencies(params)"
                  full>Сделать турфирмой
          </Button>
        </Row>
        <Row v-if="params && params.status === 'moderated'"
             class="agency-card-editor__button-holder"
             full>
          <Button color="green"
                  disabled
                  full>Заявка на модерации
          </Button>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgencyCardEditor',
  props: {
    params: {},
  },
  computed: {
    description() {
      let string = this.params.description;
      if (string.length > 80) {
        string = string.slice(0, 80);
        string = `${string}...`;
      }
      return string;
    },
    city() {
      const city = this.$cities.data.find(item => item.id === this.params.city_id);
      return city && city.name ? city.name : '';
    },
  },
};
</script>
